<script async
        setup
        lang="ts">
          const app = useNuxtApp()
          const route = useRoute()

          const { fetchData, data, isLoading } = usePloneDataset()

          await fetchData()

          type LayoutMapping = {
            [key: string]: string
          }

          const layouts: LayoutMapping = {
            cover: 'layout-cover',
            form: 'layout-form',
            news_listing: 'layout-news',
            'view-events': 'layout-events',
            profiles_listing: 'layout-profiles',
            jurors_listing: 'layout-jurors',
            probono_listing: 'layout-probono',
            faq_listing: 'layout-faq-listing',
            projects_listing: 'layout-projects',
            deset_listing: 'layout-projects',
            events_listing: 'layout-events',
            events_archive: 'layout-events',
            cpv_listing: 'layout-events',
            cpv_archive: 'layout-events',
            city_listing: 'layout-city-architects',
            press_release_listing: 'layout-press',
            statement_listing: 'layout-press',
            'view-profiles': 'layout-profiles',
            eso9: 'layout-eso9',
            CalcInt: 'layout-calculator-interiors',
            CalcPKS: 'layout-calculator-land-constructions-25',
            CalcUZ: 'layout-calculator-land-use',
            CalcRPUS: 'layout-calculator-regulatory-plan',
            CalcCOS: 'layout-calculator-competitions',
          }

          const types: LayoutMapping = {
            'News Item': 'layout-news-item',
            'Event': 'layout-event',
            'File': 'layout-file',
            'Image': 'layout-image',
            'cka.event': 'layout-event',
            'cka.section': 'layout-listing',
            'cka.project': 'layout-project',
            'cka.profile': 'layout-profile',
            'cka.competition': 'layout-competition',
            'cka.working_group': 'layout-work-group',
            'cka.calcInt': 'layout-calculator-interiors',
            'cka.calcRPUS': 'layout-calculator-regulatory-plan',
            'cka.calcPKS': 'layout-calculator-land-constructions-25',
            'cka.calcUZ': 'layout-calculator-land-use',
            'cka.calcCOS': 'layout-calculator-competitions',
            'cka.city': 'layout-city',
            'cka.school': 'layout-school',
            'cka.official_desk': 'layout-official-desk',
            'cka.official_desk_record': 'layout-official-desk-record',
            'cka.faq': 'layout-faq-item',
            'cka.labor_exchange': 'layout-labor-exchange',
            'cka.labor_ad': 'layout-labor-ad',
          }

          const layout = computed(() => {
            if (data.value.layout === 'error') {
              throw createError({ statusCode: Number(data.value?.error_code), fatal: true })
            }

            if (data.value['@id'].endsWith('pozemni-a-krajinarske-stavby-do-30-6-2024')) {
              return 'layout-calculator-land-constructions-23'
            }

            if (data.value['@id'].endsWith('pozemni-a-krajinarske-stavby-do-28-1-2025')) {
              return 'layout-calculator-land-constructions-24'
            }

            if (data.value.layout && layouts[data.value.layout]) {
              return layouts[data.value.layout]
            }

            if (types[data.value['@type']]) {
              return types[data.value['@type']]
            }

            if (data.value.layout && data.value.layout.startsWith('competitions_')) {
              return 'layout-competitions'
            }

            return 'layout-display'
          })
</script>
<template>
  <article class="page">
    <component v-if="data && layout" :class="[`layout--${layout}`]" :is="layout" :data="data" :loading="isLoading"
      @reload="fetchData" />
  </article>
</template>

<style>
  .page-enter-active {
    transition: all 0.1s ease-out;
  }

  .page-leave-active {
    transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .page-enter-from,
  .page-leave-to {
    opacity: 0;
    transform: translateY(3px);
    filter: blur(1px);
  }
</style>
